
// パスワードを忘れました and 履歴書未記入で飛ばす and コード再送
import { ref, defineComponent } from 'vue'
import * as api from '../../utils/api'
import * as storage from '../../utils/storage'
export default defineComponent({
	data() {
		const formRef = ref(null)
		const modelRef = ref({
			email: '',
			pwd: '',
			confirmCode: '',
			newPwd: '',
		})
		function pwdValidate(value: string) {
			if (!value) {
				return new Error('パスワードを入力してください。')
			} else if (value.length < 8) {
				return new Error('パスワードは8文字以上入力する必要があります。')
			} else if (value.length > 99) {
				return new Error('パスワードは99文字以上入力できません。')
			} else if (!/[a-zA-Z]/.test(value)) {
				return new Error('パスワードには英字を含める必要があります。')
			} else if (!/[0-9]/.test(value)) {
				return new Error('パスワードには数字を含める必要があります。')
			} else if (!/^[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':|_~`]{8,99}$/.test(value)) {
				return new Error('パスワードに使用できない文字が含まれています。')
			}
			return true
		}
		function mailValidate(value: string) {
			if (!value) {
				return new Error('メールアドレスを入力してください。')
			} else if (!/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9.-]+)$/.test(value)) {
				return new Error('正しい形式でメールアドレスを入力してください。')
			}
			return true
		}
		function confirmCodeValidate(value: string) {
			if (!value) {
				return new Error('検証コードを入力してください。')
			} else if (!/^[0-9]{6}$/.test(value)) {
				return new Error('正しいコードを入力してください')
			}
			return true
		}
		return {
			mode: 'login',
			formRef,
			model: modelRef,
			loading: false,
			resetVerify: false,
			resetVerifyCompleted: false,
			err: '',
			pwdValidate,
			mailValidate,
			confirmCodeValidate,
			rules: {
				email: [
					{
						required: true,
						validator(rule: any, value: string) {
							return mailValidate(value)
						},
						trigger: ['input', 'blur'],
					},
				],
				pwd: [
					{
						required: true,
						validator(rule: any, value: string) {
							return pwdValidate(value)
						},
						trigger: ['input', 'blur'],
					},
				],
				confirmCode: [
					{
						required: true,
						validator(rule: any, value: string) {
							return confirmCodeValidate(value)
						},
						trigger: ['input', 'blur'],
					},
				],
				newPwd: [
					{
						required: true,
						validator(rule: any, value: string) {
							return pwdValidate(value)
						},
						trigger: ['input', 'blur'],
					},
				],
			},
		}
	},
	mounted: function () {
		document.title = 'ログイン - Vets Index'
		localStorage.removeItem('credential')
		window.addEventListener('keydown', handleKeydown)
		const app = this
		function handleKeydown(event: any) {
			const { keyCode } = event
			if (keyCode === 13) app.post()
		}
	},
	methods: {
		post: async function (reset?: boolean) {
			const param: any = {
				username: this.model.email,
				password: this.model.pwd,
				role: 'hospital',
			}
			try {
				this.loading = true
				const em = this.mailValidate(this.model.email)
				if (em !== true) throw em
				const pwd = this.pwdValidate(this.model.pwd)
				if (pwd !== true && !reset) throw pwd
				if (reset) delete param.password
				const data = await api.postWithoutToken(reset ? '/v1/account/forget' : '/v1/login', param)
				if (reset && data.success) {
					this.loading = false
					this.resetVerify = true
					this.mode = 'confirm'
				} else if (data.accessToken) {
					storage.setItem('credential', data)
					const obj: any = location.search
						.slice(1)
						.split('&')
						.map((p) => p.split('='))
						.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
					if (data.requiredSheet) {
						this.$router.push('/hospital/fill_sheet')
					} else if (obj.go) {
						location.href = decodeURIComponent(obj.go)
					} else {
						this.$router.push('/hospital/apply_list')
					}
				} else {
					this.loading = false
					if (data.message) this.err = data.message
				}
			} catch (e: any) {
				this.loading = false
				this.err = e.message || e.toString()
				console.log(this.err)
				console.error(e)
			}
		},
		confirm: async function () {
			const param: any = {
				username: this.model.email,
				password: this.model.newPwd,
				code: this.model.confirmCode,
				role: 'hospital',
			}
			try {
				this.resetVerify = false
				this.loading = true
				const ccc = this.confirmCodeValidate(this.model.confirmCode)
				if (ccc !== true) throw ccc
				const pwd = this.pwdValidate(this.model.newPwd)
				if (pwd !== true) throw pwd
				const data = await api.postWithoutToken('/v1/account/forget_confirm', param)
				if (data.success) {
					this.resetVerifyCompleted = true
					this.loading = false
					this.mode = 'login'
				} else {
					this.loading = false
					if (data.message) this.err = data.message
				}
			} catch (e: any) {
				this.loading = false
				this.err = e.message || e.toString()
				console.log(this.err)
				console.error(e)
			}
		},
	},
})
